import { GetCreditUsageQueryParams } from './contract';
import api from './index';

export interface SubscriptionResult {
  id: number;
  plan: {
    id: number;
    name: string;
    description: string;
    monthly_credits: number;
  };
  ends_at: string;
  status: string;
  trial_ends_at: string;
  renews_at: string;
  period_months: number;
  subscription_credits: number;
}

export interface CreditUsage {
  subscription_credits: number;
  subscription_credits_used: number;
  remaining_additional_credits: number;
}

export interface CreditHistory {
  data?: {
    id: number;
    amount: number;
    expires_at: string;
    related_transaction_id: number;
    user_id: number;
    subscription_balance: number;
    additional_balance: number;
    subscription_amount: number;
    additional_amount: number;
    description: string;
    created_at: string;
  }[];
  per_page?: number;
  current_page?: number;
  last_page?: number;
  from?: number;
  to?: number;
  total?: number;
}

export interface ChargeResult {
  currency: string;
  amount: number;
  status: string;
  paid: boolean;
  description: string;
  failure_message: string;
  payment_method_type: string;
  created_at: string;
}

export async function getSubscription(orgId: number): Promise<any> {
  const url =
    `${process.env.REACT_APP_SERVER_URL}/organizations/${orgId}/subscription` +
    `?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const response = api.get(url);
  return response;
}

export async function getSubscriptionPlans(): Promise<any> {
  const url =
    `${process.env.REACT_APP_SERVER_URL}/subscriptions/plans` +
    `?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const response = api.get(url);
  return response;
}

export async function subscribe(
  orgId: number,
  price_id: string,
  currency: string,
  billing_name: string,
  billing_email: string,
  billing_country: string,
  abn?: string,
  postalcode?: string,
  is_free_trial?: boolean
): Promise<any> {
  const url =
    `${process.env.REACT_APP_SERVER_URL}/organizations/${orgId}/subscription` +
    `?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.post(url, {
    price_id,
    currency,
    billing_name,
    billing_email,
    billing_country,
    abn,
    postalcode,
    is_free_trial,
  });
}

export async function getCustomerPortalLink(orgId: number): Promise<any> {
  const url =
    `${process.env.REACT_APP_SERVER_URL}/organizations/${orgId}/billing/portal` +
    `?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}

export async function checkTrialCode(code: string): Promise<any> {
  const url =
    `${process.env.REACT_APP_SERVER_URL}/check-trial-code/${code}` +
    `?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}

export async function checkFreeTrialEligibility(): Promise<any> {
  const url =
    `${process.env.REACT_APP_SERVER_URL}/free-trial-eligibility` +
    `?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}

export async function requestFreeTrial(): Promise<any> {
  const url =
    `${process.env.REACT_APP_SERVER_URL}/request-free-trial` +
    `?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}

export async function startFreeTrial(
  orgId: number,
  trial_code: string,
  billing_name: string,
  billing_email: string,
  billing_country: string,
  abn?: string,
  postalcode?: string
): Promise<any> {
  const url =
    `${process.env.REACT_APP_SERVER_URL}/start-free-trial/${orgId}` +
    `?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.post(url, {
    trial_code,
    billing_name,
    billing_email,
    billing_country,
    abn,
    postalcode,
  });
}

export async function getCreditUsage(orgId: number): Promise<any> {
  const url =
    `${process.env.REACT_APP_SERVER_URL}/organizations/${orgId}/credit-usage` +
    `?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}

export async function getCreditHistory(
  queryParams: GetCreditUsageQueryParams
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/credit-history`;
  return api.get(url, {
    params: {
      client_id: process.env.REACT_APP_CLIENT_ID,
      ...queryParams,
    },
  });
}

export async function getAdditionalCreditInformation(): Promise<any> {
  const url =
    `${process.env.REACT_APP_SERVER_URL}/additional-package-details` +
    `?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}

export async function purchaseAdditionalCredits(
  price_id: string
): Promise<any> {
  const url =
    `${process.env.REACT_APP_SERVER_URL}/purchase-additional-credits` +
    `?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.post(url, {
    price_id,
  });
}
