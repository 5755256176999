import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface Props {
  template: any;
  generatingAssessmentTitle: string;
}

function GeneratingAssessment({
  generatingAssessmentTitle,
  template,
}: Props): JSX.Element {
  return (
    <div className="main-container generating-assessment">
      <h3 className="generating-assessment-text">
        Generating your {generatingAssessmentTitle} {template.stage}{' '}
        Assessment...
      </h3>
      <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
    </div>
  );
}

export default GeneratingAssessment;
