import React, { useEffect } from 'react';
import './ManageCredits.scss';
import NoteBanner, { NoteType } from 'components/Shared/Banners/NoteBanner';
import { RootState } from 'store/rootReducer';
import { useSelector } from 'react-redux';
import AdditionalCreditPackages from './AdditionalCreditPackages';
import AutoTopupConfig from './AutoTopupConfig';

function ManageCreditsTab(): any {
  const { subscription } = useSelector(
    (state: RootState) => state.subscription
  );
  const [message, setMessage] = React.useState('');
  useEffect(() => {
    if (subscription) {
      let message =
        "Running out of credits will severely limit your account's functionality. To keep your account fully functional, make sure you always have available credits.";
      if (subscription.status === 'trialing') {
        message +=
          ' Note that while your account is on a free trial, auto top-up will be disabled.';
      }
      setMessage(message);
    }
  }, [subscription]);

  return (
    <div className="manage-credits-page">
      <NoteBanner type={NoteType.NOTE} message={message} />
      <AutoTopupConfig />
      <AdditionalCreditPackages />
    </div>
  );
}

export default ManageCreditsTab;
