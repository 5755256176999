import React, { useCallback, useEffect, useState } from 'react';
import {
  createAssessmentFromTemplate,
  deleteAssessment,
  getTemplate,
  searchTemplates,
  updateAssessmentTitle,
} from 'api/assessment.api';
import { useToasts } from 'react-toast-notifications';
import { AssessmentPurpose } from 'components/Assessment';
import OrganizationLimitedModal from 'components/Shared/OrganizationLimitedModal';
import PlanUpgradeModal from 'components/Shared/PlanUpgradeModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { handleError } from 'handleError';
import { fetchJobTitleDetails } from 'store/actions/template.actions';
import './index.scss';
import { goToLegacyApp } from 'hooks';
import { Button } from 'components/Shared';
import { debounce } from '../../helpers/events';
import SubHeader from '../Shared/SubHeader';
import TemplateSummary from './TemplateSummary';
import GeneratingAssessment from './GeneratingAssessment';
import TemplateDetails from './TemplateDetails';
import CustomAutocomplete from './CustomAutocomplete';

interface Props {
  assessmentPurpose: AssessmentPurpose | null;
}

const TemplateList: React.FC<Props> = ({ assessmentPurpose }) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTemplateDetails, setSelectedTemplateDetails] = useState(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [
    showOrganizationLimitedModal,
    setShowOrganizationLimitedModal,
  ] = useState<boolean>(false);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [searching, setSearching] = useState<boolean>(false);
  const [roleInput, setRoleInput] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(true);
  const [createdAssessment, setCreatedAssessment] = useState<any>(null);
  const [
    generatedAssessmentTitle,
    setGeneratedAssessmentTitle,
  ] = useState<string>('');

  const [creatingFromTemplateId, setCreatingFromTemplateId] = useState<
    number | null
  >(null);

  const type = assessmentPurpose === 'ld' ? 'Exercise' : 'Assessment';
  const tests = assessmentPurpose === 'ld' ? 'exercises' : 'tests';

  const { job_titles } = useSelector((state: RootState) => state.template);

  useEffect(() => {
    if (!job_titles) {
      dispatch(fetchJobTitleDetails());
    }
  }, [job_titles, dispatch]);

  useEffect(() => {
    if (!roleInput.trim()) {
      setTemplateOptions([]);
    }
  }, [roleInput]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInputChange = useCallback(
    debounce((input) => {
      if (input.trim().length > 0) {
        searchTemplates(input)
          .then((res) => {
            const { data } = res;
            setTemplateOptions(data);
          })
          .catch((e) => {
            addToast({
              type: 'error',
              msg: e.response?.data?.message
                ? e.response?.data?.message
                : 'There was an error fetching templates',
            });
          })
          .finally(() => {
            setSearching(false);
          });
      } else {
        setTemplateOptions([]);
      }
    }, 350),
    []
  );

  useEffect(() => {
    if (isEditing && roleInput.trim()) {
      if (templateOptions.length === 0) {
        setSearching(true);
        handleInputChange(roleInput);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleInputChange, isEditing]);

  const createFromTemplate = async (
    template,
    template_manually_selected
  ): Promise<void> => {
    setIsEditing(false);
    if (selectedTemplate?.product_id === template.product_id) {
      return;
    }
    if (template_manually_selected) {
      setRoleInput(template.title);
      setGeneratedAssessmentTitle(template.title);
    } else {
      setGeneratedAssessmentTitle(roleInput);
    }
    setSelectedTemplate(template);
    setCreatingFromTemplateId(template.product_id);

    try {
      // delete the previously draft assessment when creating a different one
      if (createdAssessment) {
        deleteAssessment(createdAssessment.id);
      }
      const [
        createFromTemplateResponse,
        getTemplateResponse,
      ] = await Promise.all([
        createAssessmentFromTemplate({
          template_id: template.product_id,
          purpose: assessmentPurpose,
          role_title: template_manually_selected ? template.title : roleInput,
        }),
        getTemplate(template.product_id),
      ]);

      setCreatedAssessment(createFromTemplateResponse.data);
      setSelectedTemplateDetails(getTemplateResponse.data.data);
    } catch (error) {
      addToast({
        type: 'error',
        msg:
          error.response?.data?.message ||
          `There was an error creating your assessment`,
      });
    } finally {
      setCreatingFromTemplateId(null);
    }
  };

  const saveAssessmentTitle = (title: string): void => {
    if (!title || !createdAssessment) {
      return;
    }
    updateAssessmentTitle(
      createdAssessment.id,
      title,
      selectedTemplate.stage
    ).catch((e) => {
      handleError(e);
    });
  };

  // highlight the matched search terms in the role title in the dropdown
  const highlightMatches = (
    roleTitle: string,
    roleInput: string
  ): JSX.Element => {
    if (!roleInput) {
      return <span>{roleTitle}</span>;
    }
    const escapedSearchTerm = roleInput.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const searchWords = escapedSearchTerm.split(/\s+/).filter(Boolean);
    const regex = new RegExp(
      `(${searchWords.map((word) => `${word}`).join('|')})`,
      'gi'
    );
    const parts = roleTitle.split(regex);
    return (
      <span>
        {parts.map((part) =>
          part.toLowerCase().match(regex) ? (
            <strong style={{ fontWeight: 'bold' }}>{part}</strong>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  const formatOptionLabel = (option): JSX.Element => {
    return highlightMatches(option.title, roleInput);
  };

  return (
    <div>
      <SubHeader>
        <div className="page-title">Create {type}</div>
      </SubHeader>
      <div className="full-width-container">
        <div className="main-container">
          <div className="main-input">
            {(isEditing || !selectedTemplate) && (
              <>
                <h2>What role are you hiring for?</h2>
                <CustomAutocomplete
                  autoFocus
                  placeholder="Eg. Data Analyst, Software Engineer, etc"
                  formatOptionLabel={formatOptionLabel}
                  options={templateOptions}
                  onInputChange={(input, { action }) => {
                    if (['input-blur', 'menu-close'].includes(action)) {
                      return;
                    }
                    setRoleInput(input);
                    if (!input.trim()) {
                      setSearching(false);
                      return;
                    }
                    setSearching(true);
                    handleInputChange(input);
                  }}
                  onChange={(template, template_manually_selected) => {
                    if (!template) {
                      setRoleInput('');
                      setSelectedTemplate(null);
                      setSelectedTemplateDetails(null);
                      return;
                    }
                    createFromTemplate(template, template_manually_selected);
                  }}
                  value={roleInput}
                  setIsEditing={setIsEditing}
                />
                <div className="break" />
              </>
            )}
            {!isEditing && selectedTemplate && (
              <TemplateSummary
                title={generatedAssessmentTitle}
                setTitle={(title) => {
                  setGeneratedAssessmentTitle(title);
                  setRoleInput(title);
                }}
                template={selectedTemplate}
                onUpdateTemplate={(template) => {
                  if (template.product_id !== selectedTemplate.product_id) {
                    createFromTemplate(template, false);
                  }
                }}
                saveAssessmentTitle={(title) => {
                  saveAssessmentTitle(title);
                }}
              />
            )}
          </div>
        </div>
        {roleInput && templateOptions.length === 0 && !searching && (
          <div className="no-role-found">
            <p>
              We’re sorry, but we don’t currently offer assessments for{' '}
              {roleInput} roles. We do our best to try to cover as many roles as
              possible, so there is a good chance that we may include your role
              in the future.
            </p>
            <p>
              In the meantime try selecting a different role that you are
              currently looking to hire for, or{' '}
              <b>create a custom assessment</b>.
              <div className="create-custom-assessment">
                <Button
                  variant="primary md"
                  text="Create Custom Assessment"
                  onClick={(e) =>
                    goToLegacyApp(
                      e,
                      `create-assessment-customized-content?purpose=${assessmentPurpose}`
                    )
                  }
                />
              </div>
            </p>
          </div>
        )}
        {creatingFromTemplateId && selectedTemplate && (
          <GeneratingAssessment
            template={selectedTemplate}
            generatingAssessmentTitle={generatedAssessmentTitle}
          />
        )}
        {selectedTemplate &&
          selectedTemplateDetails &&
          !isEditing &&
          !creatingFromTemplateId && (
            <TemplateDetails
              template={selectedTemplateDetails}
              stage={selectedTemplate.stage}
              title={generatedAssessmentTitle}
              createdAssessment={createdAssessment}
            />
          )}
        <PlanUpgradeModal
          isShown={showUpgradeModal}
          setModalVisibility={setShowUpgradeModal}
          title={`Upgrade your account to create an ${type.toLowerCase()} from this
              template`}
        >
          <div>
            <p>
              This template includes {tests} that are not included on your
              current subscription plan. <br />
              Upgrade your subscription to access this template and many other
              amazing features Alooba has to offer.
            </p>
          </div>
        </PlanUpgradeModal>
        <OrganizationLimitedModal
          isShown={showOrganizationLimitedModal}
          setModalVisibility={setShowOrganizationLimitedModal}
          title="Account Limited"
        />
      </div>
    </div>
  );
};

export default TemplateList;
