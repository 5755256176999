/* eslint-disable jsx-a11y/role-has-required-aria-props */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './CustomAutocomplete.scss';

const CustomAutocomplete = ({
  options,
  onInputChange,
  onChange,
  placeholder,
  value,
  formatOptionLabel,
  autoFocus = false,
  setIsEditing,
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [activeIndex, setActiveIndex] = useState(-1);
  const inputRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);

  const handleInputChange = (e): void => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setIsOpen(true);
    setActiveIndex(-1);
    onInputChange?.(newValue, { action: 'input-change' });
  };

  const handleKeyDown = (e): void => {
    if (!options.length) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setActiveIndex((prev) => (prev < options.length - 1 ? prev + 1 : prev));
        break;
      case 'ArrowUp':
        e.preventDefault();
        setActiveIndex((prev) => (prev > -1 ? prev - 1 : -1));
        break;
      case 'Enter':
        e.preventDefault();
        if (options.length > 0) {
          const newOption =
            activeIndex === -1 ? options[0] : options[activeIndex];
          onChange?.(newOption, activeIndex !== -1);
          setIsOpen(false);
        }
        break;
      case 'Escape':
        setIsOpen(false);
        inputRef.current?.blur();
        setIsEditing(false);
        break;
      default:
        break;
    }
  };

  const handleClickOutside = useCallback(
    (e) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(e.target) &&
        !inputRef.current?.contains(e.target)
      ) {
        if (e.target.classList.contains('autocomplete-clear-btn')) return;
        setIsEditing(false);
        setIsOpen(false);
      }
    },
    [setIsEditing]
  );

  const handleClearInput = (): void => {
    setInputValue('');
    onInputChange?.('', { action: 'input-clear' });
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className={`autocomplete-container ${isOpen ? 'focused' : ''}`}>
      <div className="autocomplete-input-wrapper">
        <input
          ref={inputRef}
          type="text"
          className="autocomplete-input"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={() => setIsOpen(true)}
          placeholder={placeholder}
          autoFocus={autoFocus}
          onBlur={() => setIsOpen(false)}
          role="combobox"
        />
        {inputValue && (
          <button
            type="button"
            className="autocomplete-clear-btn"
            onClick={handleClearInput}
          >
            ✕
          </button>
        )}
      </div>
      {isOpen && options.length > 0 && (
        <div className="autocomplete-dropdown-wrapper">
          <hr />
          <div ref={menuRef} className="autocomplete-dropdown">
            {options.map((option, index) => (
              <div
                role="button"
                tabIndex={-1}
                key={option.job_title_level_id + option.title}
                className={`autocomplete-option ${
                  index === activeIndex ? 'active' : ''
                }`}
                onMouseDown={() => {
                  onChange?.(option, true);
                  setIsOpen(false);
                  setInputValue(inputValue);
                }}
                onMouseEnter={() => setActiveIndex(index)}
              >
                {formatOptionLabel(option)}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomAutocomplete;
